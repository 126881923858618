import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { BLOCKS, INLINES, MARKS } from "@contentful/rich-text-types"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import { kebabCase } from "lodash"
import React from "react"
import Fade from "react-reveal/Fade"
import { SRLWrapper } from "simple-react-lightbox"
import Layout from "../components/layout"
import SEO from "../components/seo"
import "./post.css"
import { Link, FormattedMessage,useIntl } from "gatsby-plugin-intl"
import styled from "@emotion/styled"
import tw from "twin.macro"


const Bold = ({ children }) => <span className="font-bold">{children}</span>
const Text = ({ children }) => (
  <Fade duration={1200} delay={100}>
    <p className="py-0 my-0 text-base leading-7 text-left">{children}</p>
  </Fade>
)
const website_url = "https://www.lnqradio.com"
const options = {
  renderMark: {
    [MARKS.BOLD]: (text) => <Bold>{text}</Bold>,
    [MARKS.CODE]: (embedded) => (
      <div dangerouslySetInnerHTML={{ __html: embedded }} />
    ),
  },
  renderNode: {
    [BLOCKS.EMBEDDED_ASSET]: (node) => {
      if (!node.data || !node.data.target.fields) {
        return <span className="hidden">Embedded asset is broken</span>
      }
      return (
        <div className=" post-image">
          <img
            className="w-full "
            alt={node.data.target.fields.title["es"]}
            src={node.data.target.fields.file["es"].url}
          />
        </div>
      )
    },
    [INLINES.HYPERLINK]: (node) => {
      return (
        <a
          href={node.data.uri}
          className="font-bold border-b border-red-500 hover:bg-red-700 hover:text-white"
          target={`${
            node.data.uri.startsWith(website_url) ? "_self" : "_blank"
          }`}
          rel={`${
            node.data.uri.startsWith(website_url) ? "" : "noopener noreferrer"
          }`}
        >
          {node.content[0].value}
        </a>
      )
    },
    [BLOCKS.PARAGRAPH]: (_, children) => <Text>{children}</Text>,
  },

  buttons: {
    iconPadding: "5px",
    showDownloadButton: false,
    backgroundColor: "rgba(255, 255, 255, 1)",
    iconColor: "rgb(151, 90, 22)",
  },
  caption: {
    captionFontSize: "15px",
    captionAlignment: "center",
    captionColor: "#a7825f",
    captionFontWeight: 300,
    showCaption: true,
  },
  settings: {
    overlayColor: "rgba(255, 255, 255, 1)",
    transitionTimingFunction: "ease-in-out",
    slideTransitionSpeed: 3.6,
    slideTransitionTimingFunction: [0.25, 0.75, 0.5, 1],
    slideAnimationType: "slide",
    slideSpringValues: [300, 200],
    disablePanzoom: true,
    autoplaySpeed: 3000,
    hideControlsAfter: true,
  },
  translations: {
    autoplayText: "Play",
    closeText: "Cerrar",
    downloadText: "Descargar",
    fullscreenText: "Pantalla completa",
    nextText: "Siguiente",
    pauseText: "Pausa",
    previousText: "Anterior",
    thumbnailsText: "Miniaturas",
    zoomOutText: "Zoom Out",
  },
  progressBar: {
    height: "4px",
    fillColor: "rgb(151, 90, 22)",
    backgroundColor: "rgba(255, 255, 255, 1)",
  },
  thumbnails: {
    showThumbnails: true,
  },
}

const WorkSingleTemplate = ({ data, location }) => {
  const singleWork = data.contentfulWorks
  //const works = data.allContentfulWorks.edges
  const intl = useIntl()


  return (
    <Layout location={location}>
      <SEO
        title={`${singleWork.title} | albums `}
        lang={intl.locale}
        image={`${singleWork.featuredImg.file.url}`}
      />

      <div className="w-full pt-24 m-auto mt-6 text-left">
        <header
          id={singleWork.slug}
          className="flex flex-col items-end w-full m-auto md:px-8 md:py-0 "
        >
          <div className="flex flex-col justify-start w-full px-2 m-auto md:px-0 md:flex-row">
            <div className="flex flex-col w-full max-w-4xl px-3 mx-auto mt-5 ">
              <div className="flex flex-col items-baseline w-full pb-3 mx-auto md:flex-row">
                <h1 className="flex-1 text-4xl text-left">
                  {singleWork.title}
                </h1>
                <Link
                  to={`/albums/${kebabCase(singleWork.AlbumCategory.slug)}/`}
                  className="text-lg text-yellow-700 uppercase transition-all duration-300 transform border-b border-yellow-700 opacity-75 hover:opacity-100"
                >
                  <FormattedMessage id="see" />{" "}
                  <FormattedMessage id="category" />
                </Link>
              </div>

              {singleWork.childContentfulWorksArticleRichTextNode ? (
                <Fade duration={1200} delay={100}>
                  <div className="pb-6 text-left article" id={singleWork.slug}>
                    {documentToReactComponents(
                      singleWork.childContentfulWorksArticleRichTextNode.json,
                      options
                    )}
                  </div>
                </Fade>
              ) : (
                <span className="hidden" id={singleWork.slug}></span>
              )}

              
            </div>
          </div>
        </header>
        <SRLWrapper options={options}>
          <article className="w-full max-w-6xl mx-auto overflow-hidden">
            <div>
              {singleWork.imagesWorksSecond && (
                <h1 className="text-2xl text-center text-white">
                  <FormattedMessage id="session_one" />
                </h1>
              )}
              <div className="grid w-full grid-cols-1 gap-1 px-0 pt-6 m-auto sm:grid-cols-2 md:grid-cols-3 md:py-3">
                {singleWork.imagesWorks && (
                  <>
                    {singleWork.imagesWorks.map((item, i) => (
                      <div key={item.id} className="m-0 link">
                        <Img
                          title={item.title}
                          alt={`@Sol Etchegaray - ${singleWork.title}`}
                          className="object-cover w-full transition-all duration-300 transform cursor-pointer md:h-64 hover:opacity-75"
                          fluid={item.fluid}
                        />
                      </div>
                    ))}
                  </>
                )}
              </div>
              {singleWork.imagesWorksSecond && (
                <div className="w-full px-0 pt-6 m-auto md:py-3 ">
                  <h1 className="mt-12 text-2xl text-center text-white">
                    <FormattedMessage id="session_two" />
                  </h1>
                  <div className="grid w-full grid-cols-1 gap-1 px-0 pt-6 m-auto sm:grid-cols-2 md:grid-cols-3 md:py-3">
                    {singleWork.imagesWorksSecond.map((item, i) => (
                      <div className="m-0 image-grid link">
                        <Img
                          title={item.title}
                          alt={item.title}
                          className="object-cover w-full transition-all duration-300 transform cursor-pointer md:h-64 hover:opacity-75"
                          fluid={item.fluid}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </article>
        </SRLWrapper>
      </div>

      <div class="pt-24 px-6">
        <Container id="contenido">
          {data.categories.edges.map(({ node }) => {
            return (
              <Link to={`/albums/${kebabCase(node.slug)}/`}>
                <Item key={node.slug} className="relative h-56 overflow-hidden">
                  <Img
                    title={node.title}
                    alt={node.title}
                    className="object-cover w-full h-56 bg-gray-800 "
                    fluid={node.featuredImg.fluid}
                  />
                  <h3 className="relative z-40 text-2xl tracking-wider text-center text-white uppercase duration-500 ">
                    {node.title}
                  </h3>
                </Item>
              </Link>
            )
          })}
        </Container>
      </div>
    </Layout>
  )
}

export default WorkSingleTemplate

export const pageQuery = graphql`
  query WorkBySlug($slug: String!, $locale: String) {
    contentfulWorks(slug: { eq: $slug }) {
      slug
      title
      childContentfulWorksArticleRichTextNode {
        json
      }
      AlbumCategory {
        title
        slug
      }
      imagesWorksSecond {
        title
        fluid(maxWidth: 2000) {
          # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
          ...GatsbyContentfulFluid
        }
      }
      imagesWorks {
        title
        id
        fluid(maxWidth: 2000) {
          # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
          ...GatsbyContentfulFluid
        }
        fixed(width: 100, height: 100) {
          ...GatsbyContentfulFixed
        }
      }
      featuredImg {
        fixed(width: 940, height: 300) {
          ...GatsbyContentfulFixed
        }
        file {
          url
        }
        fluid(maxWidth: 2000, resizingBehavior: SCALE) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
    }
    allContentfulWorks(
      sort: { fields: id }
      limit: 12
      filter: { slug: { ne: $slug } }
    ) {
      edges {
        node {
          id
          title
          slug
          featuredImg {
            fixed(width: 600, height: 600) {
              ...GatsbyContentfulFixed
            }
            fluid(maxWidth: 600) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
    }
    categories: allContentfulCategorias(
      sort: { fields: [title], order: ASC }
      filter: { node_locale: { eq: $locale } }
    ) {
      edges {
        node {
          id
          title
          slug
          featuredImg {
            fixed(width: 600, height: 500) {
              ...GatsbyContentfulFixed
            }
            fluid(maxWidth: 900) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
      totalCount
    }
  }
`

const Container = styled.div`
  ${tw`grid w-full max-w-6xl grid-cols-1 gap-1 pb-3 mx-auto sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-4`}
`

const Item = styled.div`
  ${tw`flex flex-col items-center justify-center w-full font-sans text-2xl `}
  transition: all 1s;

  opacity: 0.5;

  .gatsby-image-wrapper {
    position: absolute !important;
  }

  &:hover {
    opacity: 1;

    a {
      opacity: 1;
    }
  }
`
